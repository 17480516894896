import Author from '../components/author'
import Footer from '../components/footer'
import Header from '../components/header'
import HtmlContent from '../components/html-content'
import LatestPosts from '../components/latest-posts'
import Layout from '../components/layout'
import PostHeader from '../components/post-header'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

export default class Post extends React.Component {
  state = {
    noScroll: false,
  }

  render() {
    const {
      data: { post },
    } = this.props

    return (
      <Layout>
        <SEO
          title={post.fields.title}
          description={post.fields.seoDescription}
          noScroll={this.state.noScroll}
        />
        <Header onMenuChange={noScroll => this.setState({ noScroll })} />
        <PostHeader post={post} />
        <HtmlContent content={post.content} author={true} />
        {post.fields.showAuthor && <Author />}
        <LatestPosts currentId={post.wordpress_id} />
        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
  query PostById($wordpress_id: Int!) {
    post: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      wordpress_id
      date(formatString: "DD MMMM YYYY", locale: "nl")
      content
      fields: wordpress_fields {
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 820, maxHeight: 410, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        showAuthor
        seoDescription
      }
    }
  }
`
