import * as Color from 'color'

import { StaticQuery, graphql } from 'gatsby'

import Content from './content'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import Supertitle from './supertitle'
import Wrapper from './wrapper'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

export default class PostHeader extends React.Component {
  state = {
    menuOpen: false,
  }

  render() {
    const { post } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            map: file(relativePath: { eq: "map.png" }) {
              childImageSharp {
                fixed(width: 1600, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => (
          <div
            css={{
              backgroundColor: '#FAFCFF',
              backgroundImage: `url(${data.map.childImageSharp.fixed.src})`,
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '125% auto',
              paddingBottom: 40,
              paddingTop: 40,
              marginBottom: 40,

              [minWidth.tablet]: {
                marginBottom: 250,
                backgroundPosition: '50%',
                backgroundSize: 'cover',
                paddingBottom: 20,
              },

              [minWidth.laptop]: {
                paddingTop: 80,
              },
            }}
          >
            <Wrapper>
              <Content>
                <div
                  css={{
                    display: 'block',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    css={{
                      marginBottom: 30,
                      [minWidth.laptop]: {
                        marginBottom: 70,
                      },
                      '> :last-child': {
                        margin: 0,
                      },
                    }}
                  >
                    <h1
                      css={{
                        marginBottom: '0.4em',
                      }}
                    >
                      {post.fields.title}
                    </h1>
                    <Supertitle>{post.date} - door Josh & Hope</Supertitle>
                  </div>
                  <div
                    css={{
                      background: 'white',
                      borderRadius: 3,
                      boxShadow: `0 10px 50px ${Color(colors.blue60).fade(
                        0.75
                      )}`,
                      padding: 10,
                      zIndex: 1,
                      [minWidth.tablet]: {
                        marginBottom: -220,
                      },
                    }}
                  >
                    <GatsbyImage fluid={post.fields.image.localFile.childImageSharp.fluid} />
                  </div>
                </div>
              </Content>
            </Wrapper>
          </div>
        )}
      />
    )
  }
}
